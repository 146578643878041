import magicLabels from './form-magic-labels'

const form = document.querySelector('form[name="contact"]') as HTMLFormElement
const formWrap = form.parentElement
const errorContainer = form.parentElement!.querySelector('.form-error-message')!

const thanksMsg = document.createElement('div')
thanksMsg.setAttribute('tabindex', '-1')
thanksMsg.classList.add('form-response', 'title-3')
thanksMsg.innerHTML = 'your form has been submitted. thank you!'

const errorMsg = document.createElement('div')
errorMsg.setAttribute('tabindex', '-1')
errorMsg.classList.add('form-response', 'title-3')
errorMsg.innerHTML =
    'something went wrong. please refresh the page & try again.'

if (form) {
    magicLabels(form)
    form.addEventListener('submit', (e) => {
        e.preventDefault()
        // https://github.com/microsoft/TypeScript/issues/30584
        const formData = new FormData(form) as any
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then((res) => {
                if (!res.ok) {
                    throw Error(res.statusText)
                }

                form.remove()
                errorContainer.innerHTML = ''
                formWrap!.appendChild(thanksMsg)
                thanksMsg.focus()
            })
            .catch((error) => {
                console.error(error)
                errorContainer.appendChild(errorMsg)
                errorMsg.focus()
            })
    })
}
