import { animate, inView, spring } from 'motion'

export function setNumAnimations(num: HTMLElement) {
    const digits = [...num.querySelectorAll('.digit')] as HTMLElement[]

    let currentDigit = digits[0]

    let springOptions = {
        velocity: 0,
        stiffness: 70,
        damping: 7,
    }

    inView(
        '#how .step',
        (enterInfo) => {
            const target = enterInfo.target as HTMLElement
            const i = target.dataset.i
            const newDigit = digits.find(
                (d) => d.dataset?.i === i
            ) as HTMLElement

            animate(
                currentDigit,
                {
                    scale: [1, 0.9],
                    y: [0, '100%'],
                    rotateX: [0, -50],
                    opacity: 0,
                },
                { easing: spring(springOptions) }
            )
            animate(
                newDigit,
                {
                    scale: [0.9, 1],
                    y: ['-100%', 0],
                    rotateX: [50, 0],
                    opacity: [0, 1],
                },
                { duration: 1, easing: spring(springOptions) }
            )
            // animate(target, {
            //     opacity: [0, 1],
            // })

            currentDigit = newDigit

            return (leaveInfo) => {
                // const target = enterInfo.target as HTMLElement
                // animate(target, {
                //     opacity: [1, 0],
                // })
                // console.log('leave', leaveInfo.target?.dataset?.i)
            }
        },
        {
            margin: '-50% 0px -50% 0px',
            // margin: '-90% 0px -5% 0px',
        }
    )
}
