import { GradientBg } from './bg-animations'
import { sectionOptions } from './bg-animations-props'
import { animateComputer } from './computer'
import { setNumAnimations } from './how-steps'
import { animate } from 'motion'
import './modal'
import './form'
import './animations'

const laptopPieces = [
    {
        url: new URL('../img/laptop-top.svg', import.meta.url),
        el: document.getElementById('laptop-top'),
    },
    {
        url: new URL('../img/laptop-bottom.svg', import.meta.url),
        el: document.getElementById('laptop-bottom'),
    },
    {
        url: new URL('../img/laptop-lid.svg', import.meta.url),
        el: document.getElementById('laptop-screen'),
    },
    {
        url: new URL('../img/laptop-content.jpg', import.meta.url),
        el: document.querySelector('#laptop-display'),
    },
]

const loadImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.addEventListener('load', () => resolve(img))
        img.addEventListener('error', reject)
        img.src = url
    })
}

function setupBgs(container: HTMLElement) {
    for (let i = 0; i < sectionOptions.length; i++) {
        let opts = sectionOptions[i]
        let gr = new GradientBg(container, opts.trigger, opts.options)
    }
}

async function setupStuff() {
    const num = document.getElementById('how-num')

    const bgContainer = document.getElementById('bg-gradient')
    const computer = document.querySelector('.computer')
    const header = document.querySelector('#header')

    window.scrollTo(0, 0)

    try {
        const imgs = await Promise.all(
            laptopPieces.map((item) => loadImage(item.url.href))
        )
        laptopPieces.forEach((item, i) => item.el?.appendChild(imgs[i]))

        if (computer && header) animateComputer(computer, header)
        if (bgContainer) setupBgs(bgContainer)
        if (num) setNumAnimations(num)
        animate('.starthidden', { opacity: 1 }, { duration: 0.5, delay: 0.5 })
    } catch (err) {
        console.error(err)
        if (computer) computer.remove()
        if (bgContainer) setupBgs(bgContainer)
        if (num) setNumAnimations(num)
        animate('.starthidden', { opacity: 1 }, { duration: 0.3 })
    }
}

document.addEventListener('DOMContentLoaded', () => {
    setupStuff()
})
