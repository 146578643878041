import * as focusTrap from 'focus-trap'

class Modal {
    elOuter: HTMLElement
    elInner: HTMLElement
    btns: Element[]
    trap: focusTrap.FocusTrap
    closeBtn: HTMLElement

    constructor(el: HTMLElement) {
        this.elOuter = el
        this.elInner = el.querySelector('.modal-inner')!
        this.closeBtn = el.querySelector('.modal-close')!

        let id = this.elOuter.getAttribute('id')
        this.btns = [...document.querySelectorAll(`[data-modal=${id}]`)]

        this.trap = focusTrap.createFocusTrap(this.elInner, {
            clickOutsideDeactivates: true,
            onDeactivate: () => {
                this.elInner.addEventListener(
                    'transitionend',
                    () => (this.elOuter.style.display = 'none'),
                    { once: true }
                )
                this.elOuter.classList.remove('is-open')
                document.documentElement.style['overflow'] = 'auto'
            },
        })

        this.elOuter.style.display = 'none'

        this.clickInit()
    }

    clickInit() {
        this.btns.forEach((btn) => {
            btn.addEventListener('click', () => this.open())
        })

        this.closeBtn.addEventListener('click', () => {
            this.trap.deactivate()
        })
    }

    close() {
        this.trap.deactivate()
    }

    open() {
        this.elOuter.style.display = 'flex'
        setTimeout(() => this.elOuter.classList.add('is-open'))
        this.elInner.setAttribute('aria-hidden', 'false')
        document.documentElement.style['overflow'] = 'hidden'
        this.trap.activate()
    }
}

let modalEl = document.querySelector('.modal')
if (modalEl instanceof HTMLElement) {
    new Modal(modalEl)
}
