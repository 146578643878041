import type { SectionOpts } from './bg-animations'
import { animate, stagger } from 'motion'

export const sectionOptions: {
    trigger: string
    options: SectionOpts
}[] = [
    {
        trigger: '#top-nav',
        options: {
            backgrounds: [
                {
                    fill: '#bg-gradient-1',
                    animations: [
                        {
                            prop: 'x',
                            vals: ['20%', '10%', '20%'],
                            duration: 20,
                        },
                        {
                            prop: 'y',
                            vals: ['-10%', '10%', '-10%'],
                            duration: 21,
                        },
                        {
                            prop: 'rotate',
                            vals: [0, 360],
                            duration: 7,
                        },
                    ],
                },
                {
                    fill: '#bg-gradient-2',
                    animations: [
                        {
                            prop: 'x',
                            vals: ['-10%', '10%', '-10%'],
                            duration: 24,
                        },
                        {
                            prop: 'y',
                            vals: ['-30%', '-10%', '-30%'],
                            duration: 23,
                        },
                        {
                            prop: 'rotate',
                            vals: [0, 360],
                            duration: 12,
                        },
                    ],
                },
                {
                    fill: '#bg-gradient-3',
                    animations: [
                        {
                            prop: 'x',
                            vals: ['0%', '25%', '0%'],
                            duration: 25,
                        },
                        {
                            prop: 'y',
                            vals: ['-10%', '15%', '-10%'],
                            duration: 12,
                        },
                        {
                            prop: 'rotate',
                            vals: [360, 0],
                            duration: 9,
                        },
                    ],
                },
            ],
        },
    },

    {
        trigger: '#bg-wrap',
        options: {
            margins: '-50%',
            backgrounds: [
                {
                    fill: '#bg-gradient-4',
                    animations: [
                        {
                            prop: 'rotate',
                            vals: [0, 180, 0],
                            duration: 25,
                        },
                        {
                            prop: 'x',
                            vals: ['-10%', '5%', '-10%'],
                            duration: 15,
                        },
                    ],
                },
            ],
        },
    },
    {
        trigger: '#how-title',
        // amount: 1,
        options: {
            margins: '20% 0px -50% 0px',
            backgrounds: [
                {
                    fill: '#bg-gradient-6',
                    origin: '100% 0%',
                    props: {
                        height: '70%',
                    },
                    animations: [
                        {
                            prop: 'x',
                            vals: ['15%', '20%', '15%'],
                            duration: 16,
                        },
                        {
                            prop: 'rotate',
                            vals: [0, 10, 0],
                            duration: 10,
                        },
                    ],
                },
            ],
        },
    },
    {
        trigger: '#how-steps',
        options: {
            margins: '-50%',
            onFirstEnter: (animations, els) => {
                console.log({ animations, els })
            },
            backgrounds: [
                {
                    fill: '#bg-gradient-5',
                    origin: '50% 10%',
                    animations: [
                        {
                            prop: 'rotate',
                            vals: [20, -50, 20],
                            duration: 18,
                        },
                        {
                            prop: 'scale',
                            vals: [1, 1.1, 1],
                            duration: 12,
                        },
                    ],
                },
                {
                    fill: '#bg-gradient-7',
                    origin: '70% 60%',
                    animations: [
                        {
                            prop: 'rotate',
                            vals: [0, 360],
                            duration: 10,
                        },
                        {
                            prop: 'x',
                            vals: ['0%', '10%', '0%'],
                            duration: 15,
                        },
                    ],
                },
            ],
        },
    },
]
