import { animate, inView, spring, stagger, BezierDefinition } from 'motion'

const easings: {
    // [key: string]: [number, number, number, number]
    [key: string]: BezierDefinition
} = {
    quint_in: [0.755, 0.06, 0.855, 0.06],
    quint_out: [0.23, 1, 0.32, 1],
    quint_inout: [0.86, 0, 0.07, 1],
    circ_out: [0, 0.55, 0.45, 1],
    circ_inout: [0.85, 0, 0.15, 1],
    quad_in: [0.11, 0, 0.5, 0],
    quad_out: [0.5, 1, 0.89, 1],
    quad_inout: [0.45, 0, 0.55, 1],
}

const intro: HTMLElement = document.getElementById('intro')!
const what: HTMLElement = document.getElementById('what')!

if (intro) animateIntro(intro)
if (what) animateWhat(what)

function animateIntro(el: HTMLElement) {
    const media = el.querySelectorAll('.media')

    inView(el, (enterInfo) => {
        animate(
            media,
            {
                opacity: [0, 1],
                y: ['-50%', 0],
            },
            {
                delay: stagger(0.3, { start: 0.5, easing: easings.quad_out }),
                duration: 0.5,
                easing: easings.quad_in,
            }
        )

        // return (leaveInfo) => {}
    })
}

function animateWhat(el: HTMLElement) {
    const desc = what.querySelectorAll('.desc')
    const nums = what.querySelectorAll('.num')

    const staggerAmount = 0.6
    const dur = 0.5

    inView(el, (enterInfo) => {
        animate(
            nums,
            {
                opacity: [0, 1],
                rotateX: [-30, 0],
                rotateY: [-50, 0],
            },
            {
                delay: stagger(staggerAmount, {
                    start: 0.2,
                }),
                duration: dur,
                easing: easings.quad_in,
            }
        )

        animate(
            desc,
            {
                opacity: [0, 1],
                rotateX: [-10, 0],
            },
            {
                delay: stagger(staggerAmount, {
                    start: 0.25,
                }),
                duration: dur,
                easing: easings.quad_in,
            }
        )
    })
}
