export default function magicLabels(form) {
    let fieldWrappers = form.querySelectorAll('.magic-label')

    fieldWrappers.forEach((wrap) => {
        let input =
            wrap.querySelector('input') ||
            wrap.querySelector('textarea') ||
            wrap.querySelector('select')

        if (input?.value !== '') {
            wrap.classList.add('move-label')
        }

        input?.addEventListener('focus', () => {
            wrap.classList.add('move-label')
        })

        input?.addEventListener('blur', () => {
            if (input.value.length === 0) {
                wrap.classList.remove('move-label')
            }
        })
    })
}
