import { AnimationControls, animate, inView } from 'motion'

function attributes(el: Element, attrs: {}): void {
    Object.keys(attrs).forEach((att) => {
        el.setAttribute(att, attrs[att])
    })
}

type AnimationOptions = {
    prop: string
    vals: (number | string)[]
    duration: number
}

type BackgroundOptions = {
    fill: string
    props?: {}
    origin?: string
    animations: null | AnimationOptions[]
}

export type SectionOpts = {
    backgrounds: BackgroundOptions[]
    margins?: string
    amount?: number | 'any' | 'all'
    skipSetTriggers?: boolean
    onFirstEnter?: (animations: AnimationControls[], els: Element[]) => void
}

type AnimationsList = {
    el: Element
    controls: AnimationControls[]
}[]

export class GradientBg {
    trigger: Element
    margins?: string = '0px'
    amount?: number | 'any' | 'all'
    bgOptions: BackgroundOptions[]
    skipSetTriggers?: boolean
    animations: AnimationControls[] = []
    container: Element
    onFirstEnter?: (
        animations: AnimationControls[],
        els: Element[]
    ) => void | undefined
    enteredOnce = false
    els: Element[] = []

    constructor(container: HTMLElement, trigger: string, options: SectionOpts) {
        this.container = container
        let triggerEl = document.querySelector(trigger)
        if (!(triggerEl instanceof Element)) {
            console.warn(`🤔 can't find this element! ${trigger}`)
            return
        }

        this.trigger = triggerEl
        this.margins = options.margins ?? '0px'
        this.amount = options.amount ?? 'any'
        this.skipSetTriggers = options.skipSetTriggers ?? false
        this.bgOptions = options.backgrounds
        if (options.onFirstEnter) this.onFirstEnter = options.onFirstEnter

        this.setupBg()
    }

    setupBg() {
        this.bgOptions.forEach((options) => {
            this.setOneBg(options)
        })
        if (!this.skipSetTriggers) this.setTriggers()

        console.log(this)
    }

    setOneBg(options) {
        let el = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
        this.container.appendChild(el)

        attributes(el, {
            fill: `url(${options.fill})`,
            x: options.props?.x ?? '0',
            y: options.props?.y ?? '0',
            width: options.props?.width ?? '100',
            height: options.props?.height ?? '100',
            'transform-origin': options.origin ?? '50% 30%',
            opacity: 0,
        })

        options.animations?.forEach((anim) => {
            let animProps = {}
            animProps[anim.prop] = anim.vals
            let control = animate(el, animProps, {
                duration: anim.duration,
                repeat: Infinity,
                easing: 'linear',
            })
            this.animations.push(control)
            control.pause()
        })

        this.els.push(el)
    }

    setTriggers() {
        inView(
            this.trigger,
            (enterInfo) => {
                // console.log(this.trigger, 'enter')

                if (!this.enteredOnce) {
                    this.enteredOnce = true
                    if (this.onFirstEnter) {
                        this.onFirstEnter(this.animations, this.els)
                        return
                    }
                }

                this.animations.forEach((item) => {
                    item.play()
                })

                animate(this.els, { opacity: 1 }, { duration: 1 })

                return (leaveInfo) => {
                    // console.log(this.trigger, 'leave')
                    animate(this.els, { opacity: 0 }, { duration: 1 })
                    this.animations.forEach((item) => {
                        item.pause()
                    })
                }
            },
            {
                margin: this.margins,
            }
        )
    }
}
