import { scroll, animate } from 'motion'

export function animateComputer(computer: Element, spacer: Element) {
    let top = computer.querySelector('.top')
    if (!top) return

    scroll(
        animate(top, {
            transform: [
                'translate3d(var(--top-x), var(--top-y), var(--top-z)) rotateX(-90deg)',
                'translate3d(var(--top-x), var(--top-y), var(--top-z)) rotateX(0deg)',
            ],
        }),
        {
            target: spacer,
            offset: ['100px start', 'end end'],
        }
    )
}

const getSceneHeight = () => {
    return (
        Math.min(window.innerWidth * 0.47, window.innerHeight * 0.9, 680) * 1.3
    )
}
